import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../styles/EditarChequeo.module.css"; // Add custom styles for blurred background and modal
import { toast } from "react-toastify";
import TopWindowBar from "./TopWindowBar";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const EditarChequeo = ({ chequeoId, matricula, onClose }) => {
  const [chequeo, setChequeo] = useState(null);
  const [formData, setFormData] = useState({
    DHSalida: "",
    KMSSalida: "",
    CombSalida: "",
    DHEntrada: "",
    KMSEntrada: "",
    CombEntrada: "",
  });

  useEffect(() => {
    // Fetch chequeo data from backend
    const fetchChequeo = async () => {
      try {
        const response = await axios.get(
          `/api/contratos.php?tipo=2&contrato=${chequeoId}&matricula=${matricula}`
        );
        setChequeo(response.data.data);
        setFormData({
          DHSalida: response.data.data.DHSalidaF || "",
          KMSSalida: response.data.data.KMSSalida || "",
          CombSalida: response.data.data.CombSalida || "",
          DHEntrada: response.data.data.DHEntradaF || "",
          KMSEntrada: response.data.data.KMSEntrada || "",
          CombEntrada: response.data.data.CombEntrada || "",
        });
      } catch (error) {
        console.error("Error fetching chequeo data:", error);
      }
    };

    fetchChequeo();
  }, [chequeoId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = new FormData();
      data.append("contrato", chequeoId);
      data.append("matricula", matricula);
      data.append(
        "DHSalidaF",
        formData.DHSalida.slice(0, 19).replace("T", " ")
      );
      data.append(
        "DHEntradaF",
        formData.DHEntrada.slice(0, 19).replace("T", " ")
      );
      data.append("modo", 8);
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      await axios.post(`/api/contratos.php`, data);
      toast.success("Chequeo editado correctamente");
      onClose();
    } catch (error) {
      console.error("Error saving chequeo data:", error);
    }
  };

  if (!chequeo) {
    return <div className="modal">Cargando...</div>;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.main}>
        <TopWindowBar
          title={"Editar Chequeo " + chequeoId}
          icon={faCheckCircle}
          backroute={() => onClose()}
          isFunction={true}
        />
        <form onSubmit={handleSubmit} className={styles.form}>
          {chequeo.Chequeado === "1" || chequeo.Chequeado === "2" ? (
            <>
              <div>
                <label>Fecha y Hora Salida:</label>
                <input
                  type="datetime-local"
                  name="DHSalida"
                  className={styles.formInput}
                  value={formData.DHSalida}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Kms de Salida:</label>
                <input
                  type="text"
                  name="KMSSalida"
                  className={styles.formInput}
                  value={formData.KMSSalida}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Combustible Salida:</label>
                <input
                  type="text"
                  name="CombSalida"
                  className={styles.formInput}
                  value={formData.CombSalida}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <p></p>
          )}
          {chequeo.Chequeado === "2" ? (
            <>
              <div>
                <label>Fecha y Hora Entrada:</label>
                <input
                  type="datetime-local"
                  name="DHEntrada"
                  className={styles.formInput}
                  value={formData.DHEntrada}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Kms de Entrada:</label>
                <input
                  type="number"
                  name="KMSEntrada"
                  className={styles.formInput}
                  value={formData.KMSEntrada}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Combustible Entrada:</label>
                <input
                  type="text"
                  name="CombEntrada"
                  className={styles.formInput}
                  value={formData.CombEntrada}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <p></p>
          )}
          <button type="submit">Guardar</button>
        </form>
      </div>
    </div>
  );
};

export default EditarChequeo;
